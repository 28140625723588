import {
  socket,
  users,
  user,
  rooms,
  twitches,
  chatMessages,
  wuffwoff,
} from "../store"

export const connect = nickName => {
  if (socket.isConnecting || socket.isOpen) return
  console.log("trying to connect to:", process.env.WS_URL)
  socket.isConnecting = true
  const ws = new WebSocket(process.env.WS_URL)

  ws.onopen = () => {
    socket.isOpen = true
    socket.isConnecting = false

    // let's send the hello message to the server
    socket.sendObj({
      hello: {
        nickName: nickName,
      },
    })
  }

  ws.onclose = code => {
    socket.isOpen = false
    socket.isConnecting = false
    console.warn("close", code)
    // let's try again
    setTimeout(() => connect(nickName), 1500)
  }

  ws.onerror = event => {
    console.error("WebSocket error observed:", event)
    if (socket.isConnecting) {
      // let's try again
      setTimeout(() => connect(nickName), 1500)
    }
    ws.close()
  }

  ws.onmessage = message => {
    try {
      const data = JSON.parse(message.data)

      if (data.id) {
        user.id = data.id
      }
      if (data.nickName) {
        user.nickName = data.nickName
      }
      if (data.users) {
        users.info = data.users
      }
      if (data.wuffwoff) {
        wuffwoff.text = data.wuffwoff
      }

      if (data.chat) {
        const now = new Date().getTime()
        chatMessages.push({ ...data.chat, time: now })
        chatMessages.forEach((msg, i) => {
          const age = now - msg.time
          if (age > 30000) chatMessages.splice(i, i + 1)
        })
      }

      if (data.roomInfo) {
        if (data.isReset) {
          rooms.length = 0
        }
        data.roomInfo.forEach((room, i) => {
          const existing = rooms.find(r => r.name === room.name)
          if (existing) {
            Object.assign(existing, room)
          } else {
            rooms.push(room)
          }
        })
      }
      if (data.deleteRoom) {
        rooms.replace(rooms.filter(r => r.name !== data.deleteRoom))
      }

      if (data.twitchInfo) {
        if (data.isReset) {
          twitches.length = 0
        }
        data.twitchInfo.forEach(twitch => {
          const existing = twitches.find(t => t.key === twitch.key)
          if (existing) {
            Object.assign(existing, twitch)
          } else {
            twitches.push(twitch)
          }
        })
      }
      if (data.deleteTwitch) {
        twitches.replace(twitches.filter(t => t.key !== data.deleteTwitch))
      }
    } catch (e) {
      console.error("cannot interpret message", message.data, e)
    }
  }

  socket.send = message => {
    ws.send(message)
  }
  socket.sendObj = message => {
    ws.send(JSON.stringify(message))
  }

  socket.sendChat = (message, chatId = "main") =>
    socket.sendObj({
      chat: {
        id: chatId,
        nick: user.nickName,
        value: message,
      },
    })

  return { ws }
}
