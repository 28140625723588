import React from "react"
import Cookies from "js-cookie"

import { connect } from "./src/websocket"
import { socket } from "./src/store"
import Layout from "./src/components/layout"
import NickName from "./src/components/nickname"

const Wrap = ({ children }) => {
  const [isReady, setIsReady] = React.useState(false)
  React.useEffect(() => {
    // check if user has a cookie for the username
    let nickName = Cookies.get("nickName")
    if (!!nickName) {
      socket.nickName = nickName
      setIsReady(true)
    }
  }, [])

  React.useEffect(() => {
    if (!isReady) return
    let ws = connect(Cookies.get("nickName"))
    return () => {
      if (ws && !!ws.close) ws.close()
    }
  }, [isReady])

  return (
    <Layout>
      {!isReady ? (
        <NickName
          onSet={name => {
            Cookies.set("nickName", name)
            setIsReady(true)
          }}
        />
      ) : (
        children
      )}
    </Layout>
  )
}

export default ({ element }) => {
  return <Wrap>{element}</Wrap>
}
