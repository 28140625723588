/** @jsx jsx */
import { Global, css, jsx } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"

const theme = {
  color: { fg: "#FFF", bg: "#000", special: "#39325e" },
  mobileBreakpoint: "40em",
  resetButton: css`
    appearance: none;
    font: inherit;
    background: transparent;
    color: inherit;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-shadow: -1px 0 0 #000, 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000;
    color: white;

    &:focus,
    &:active,
    &:hover {
      color: #0c9cdb;
      outline: none;
      color: ;
    }
  `,
}

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Global
        styles={css`
          @font-face {
            font-family: WOFF;
            src: url("/font/WOFF.woff2") format("woff2"),
              url("/font/WOFF.woff") format("woff");
            font-weight: normal;
            font-style: normal;
          }

          html,
          body {
            margin: 0;
            padding: 0;
            font-size: 100%;
            width: 100%;
            height: 100%;
          }

          body {
            font-family: "WOFF";
            font-weight: 400;
            background: ${theme.color.bg};
            color: ${theme.color.fg};
            text-shadow: -1px 0 0 #000, 1px 0 0 #000, 0 -1px 0 #000,
              0 1px 0 #000;
            pointer-events: none;
          }

          iframe {
            pointer-events: all;
          }

          #___gatsby,
          #gatsby-focus-wrapper {
            width: 100%;
            height: 100%;
          }

          #___gatsby {
            box-sizing: border-box;
            padding: 0.2em;
            position: relative;
          }
        `}
      />
      {children}
    </ThemeProvider>
  )
}

export default Layout
