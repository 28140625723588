import { useState } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { motion } from "framer-motion"
import Logo from "./logo"

export default ({ onSet }) => {
  const [value, setValue] = useState("")
  const isValid = value.length >= 3
  return (
    <div
      css={theme => css`
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        pointer-events: all;
        text-align: center;
        background-image: url(/icons/bg_salzburg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        color: white;
        text-shadow: 0 0 0.1em ${theme.color.special};
      `}
    >
      <Logo
        css={css`
          fill: currentColor;
          width: 18em;
          margin: auto 0 2em;
        `}
      />
      <div
        css={theme => css`
          font-size: 150;
          margin: 0.4em 0;
        `}
      >
        ☞ Best experienced with Chrome or Firefox on a computer ☜
      </div>
      <div
        css={css`
          text-transform: uppercase;
          font-size: 200%;
        `}
      >
        Choose your name:
      </div>
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <input
          type="text"
          value={value}
          onChange={ev => setValue(ev.target.value)}
          onKeyDown={ev => {
            if (ev.key === "Enter") {
              if (!isValid) return
              onSet(value)
            }
          }}
          css={theme => css`
            appearance: none;
            font: inherit;
            color: inherit;
            border: 2px solid currentColor;
            background: inherit;
            font-size: 200%;
            max-width: 60%;

            &:focus,
            &:active {
              outline: 2px solid ${theme.color.special};
            }
          `}
        />
        {isValid && (
          <button
            css={theme => [
              theme.resetButton,
              css`
                cursor: pointer;
                background: white;
                color: black;
                font-size: 200%;
                margin: -2px 0;
                display: block;
                padding: 7px 4px 0;
                height: 100%;
                box-sizing: border-box;
              `,
            ]}
            onClick={() => {
              if (!isValid) return
              onSet(value)
            }}
          >
            Ok
          </button>
        )}
      </div>
      <div
        css={css`
          position: relative;
          font-size: 80%;
          margin-top: 0.4em;
        `}
      >
        <motion.div
          animate={{
            opacity: isValid ? 1 : 0,
            y: isValid ? "0%" : "100%",
          }}
          css={css`
            position: absolute;
            left: 0;
            width: 100%;
            font-size: 200%;
          `}
        >
          ✌
        </motion.div>
        <motion.div
          animate={{
            opacity: isValid ? 0 : 1,
            y: isValid ? "-100%" : "0%",
          }}
        >
          Name has to be at least 3 Characters
        </motion.div>
      </div>
      <div
        css={theme => css`
          font-size: 150%;
          background-color: ${theme.color.special};
          margin: auto 0 2em;
          border: 1px solid white;
          padding: 0.2em;
        `}
      >
        ✋ Beware ✋<br /> we use Cookies to store your choice
      </div>
    </div>
  )
}
