import { observable, action, reaction } from "mobx"

export const user = observable({
  nickName: undefined,
  viewMode: "app",
  chatMode: "main",
})

export const users = observable({ info: [] })

export const dj = observable(
  {
    player: null,
    muted: true,
    setMuted(b) {
      dj.player.setMuted(b)
      dj.muted = b
    },
    setVolume(v) {
      dj.player && dj.player.setVolume(v)
    },
  },
  {
    setMuted: action,
  }
)

export const rooms = observable([])

export const twitches = observable([])

export const socket = observable({
  isOpen: false,
  send: msg => {},
  sendObj: msg => {},
})

export const chatMessages = observable([])
export const wuffwoff = observable({
  text: "Loading…",
})

reaction(
  () => [user.viewMode, user.jitsiRoom, user.twitchChannel, socket.isOpen],
  data => {
    if (!socket.isOpen) return
    socket.sendObj({
      userInfo: [user],
    })
  }
)
